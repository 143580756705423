import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { PAGE } from '@gp-angular/shared/schema';
import { ProviderService } from '@gp-angular/service/provider';
import { UserService } from '@gp-angular/service/user';
import { PersonListInfoDTO } from '@noventi/gp-platform/care-providers';
import { Router } from '@angular/router';

@Component({
	selector: 'gp-provider-institution-select',
	templateUrl: './institution-select.component.html',
	styleUrls: ['./institution-select.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProviderInstitutionSelectComponent {

	public readonly PAGE = PAGE;

	public loading = true;

	public opened: boolean;

	public list$: Observable<Array<PersonListInfoDTO>> = this.userService.readUsername$()
		.pipe(
			filter(username => !!username),
			tap(username => this.providerService.loadProviderList(username)),
			switchMap(() => this.providerService.getProviderList$()),
			debounceTime(100),
			tap(() => this._loading(false))
		);

	public defaultPid$: Observable<number> = this.userService.readDefaultPid$();

	public currentPid$: Observable<number> = this.userService.getCurrentPid$();

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private providerService: ProviderService,
		private router: Router,
		private userService: UserService
		) {
	}

	public getCurrentPid(id: number, list: Array<PersonListInfoDTO>): PersonListInfoDTO {
		return list.find((item => item.id === id));
	}

	public setCurrentPid(id: number): void {
		this.userService.setCurrentPid(id);
		this.router.navigate([PAGE.PROVIDER.fullpath]).then();
	}

	private _loading(value: boolean = false) {
		this.loading = value;
		this.changeDetectorRef.markForCheck();
	}
}
