import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable()
export class PathService {

	private _subscription: Subscription;

	private _subscriptionBack: Subscription;

	constructor(
		private _titleService: Title,
		private _router: Router,
		private _translateService: TranslateService,
		private _matDialog: MatDialog
	) {
	}

	public init() {
		this.destroy();
		this._subscription = this._router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => [
					...this.getTitle(this._router.routerState, this._router.routerState.root),
					this._translateService.instant('App.Name')
				].join(' - '))
			).subscribe(title => {
				this._titleService.setTitle(title);
			});

		this._subscriptionBack = this._router.events
			.pipe(
				filter(event => event instanceof NavigationStart),
				tap(() => this._matDialog.closeAll())
			).subscribe();
	}

	/**
	 * Get the the Title of parents recursively and combine it
	 */
	private getTitle(state: any, parent: any) {
		const data: string[] = [];
		if (parent && parent.snapshot.data && parent.snapshot.data.title) {
			data.push(this._translateService.instant(parent.snapshot.data.title));
		}

		if (state && parent) {
			data.push(...this.getTitle(state, state.firstChild(parent)));
		}

		return data;
	}

	public destroy() {
		if (this._subscription && !this._subscription.closed) {
			this._subscription.unsubscribe();
		}
		this._subscription = undefined;

		if (this._subscriptionBack && !this._subscriptionBack.closed) {
			this._subscriptionBack.unsubscribe();
		}
		this._subscriptionBack = undefined;
	}
}
