import { PAGE } from '@gp-angular/shared/schema';
import { AppTourDismissDTO, AppTourDTO } from '@gp-angular/api-kds/onboarding';

const BUTTON_SKIP = {type: 'mat-button', color: 'primary', name: 'Abbrechen', close: true};

export const AppProviderTourDropping: AppTourDismissDTO = {
	step: 999,
	overlay: true,
	description: `Sind Sie sicher, dass sie die Einführung abbrechen möchten?`,
	reload: `Ich möchte die Tour beim nächsten Start von Borino wieder beginnen.`,
	buttons: [
		{
			type: 'mat-button',
			color: 'primary',
			name: 'NEIN',
			revert: true
		},
		{
			type: 'mat-flat-button',
			color: 'primary',
			name: 'JA',
			drop: true
		}
	]
}
export const AppProviderTour: Array<AppTourDTO> = [
	{
		// STEP: INTRO
		step: 0,
		overlay: true,
		title: {
			content: 'Willkommen bei NOVENTI Borino!'
		},
		description: `Zum Einstieg empfehlen wir Ihnen unsere kurze Tour. Wir helfen Ihnen dabei, Ihr Profil zu vervollständigen.`,
		buttons: [BUTTON_SKIP,
			{
				type: 'mat-flat-button',
				color: 'primary',
				name: 'Start',
				url: PAGE.PROVIDER_PROFILE.fullpath,
				stepUpdate: 1
			}
		],
		hideMinimize: true
	},
	{
		// STEP: VERIFY PROFILE DATA
		step: 1,
		percent: 0,
		info: '1/5',
		title: {
			content: 'Einführung'
		},
		description: 'Persönliche Daten',
		detail: `<p>Bitte prüfen Sie, ob die Angaben korrekt sind. Geben Sie fehlende Daten ein und klicken Sie auf <strong>Speichern</strong>. 
						Wenn keine Änderungen erforderlich sind, klicken Sie hier im Fenster auf <strong>OK</strong>.</p>`,
		buttons: [BUTTON_SKIP,
			{
				type: 'mat-flat-button',
				color: 'primary',
				name: 'OK',
				url: PAGE.PROVIDER_RESOURCE.fullpath,
				stepUpdate: 2
			}
		]
	},
	{
		// STEP: ADD/EDIT RESOURCE
		step: 2,
		percent: 20,
		info: '2/5',
		title: {
			content: 'Einführung'
		},
		description: `Ressourcen – Mitarbeiter`,
		detail: `<p>Hier finden Sie den bereits angelegten Mitarbeiter. Sie können diesen über das Stift-Symbol rechts bearbeiten. 
						Weitere Mitarbeiter können Sie über <strong>Mitarbeiter hinzufügen</strong> erstellen.</p>`,
		buttons: [BUTTON_SKIP]
	},
	{
		// STEP: ADD RESOURCE
		step: 3,
		percent: 20,
		info: '2/5',
		title: {
			content: 'Einführung'
		},
		description: `Mitarbeiter`,
		detail: `<p>Geben Sie jetzt die Daten des Mitarbeiters ein und klicken Sie dann auf Speichern.</p>`,
		buttons: [BUTTON_SKIP]
	},
	{
		// STEP: BOOKING HOURS
		step: 4,
		percent: 40,
		info: '3/5',
		title: {
			content: 'Einführung'
		},
		description: `Arbeitszeiten hinzufügen`,
		detail: `<p>Klicken Sie auf <strong>Arbeitszeit hinzufügen</strong> um die Arbeitszeiten zu hinterlegen. 
						Sie können mehrere Arbeitszeiten für einen Mitarbeiter festlegen, um Pausen abzubilden.</p>`,
		buttons: [BUTTON_SKIP]
	},
	{
		// STEP: ADD BOOKING HOURS
		step: 5,
		percent: 40,
		info: '3/5',
		title: {
			content: 'Einführung'
		},
		description: `Arbeitszeiten hinzufügen`,
		detail: `<p>Sie können jetzt noch weitere Arbeitszeiten hinzufügen. Andernfalls klicken Sie bitte hier im Fenster auf <strong>OK</strong>.</p>`,
		buttons: [BUTTON_SKIP,
			{
				type: 'mat-flat-button',
				color: 'primary',
				name: 'OK',
				stepUpdate: 6
			}
		]
	},
	{
		// STEP: ONLINE BOOKING HOURS
		step: 6,
		percent: 60,
		info: '4/5',
		title: {
			content: 'Einführung'
		},
		description: `Online-Freigaben`,
		detail: `<p>Legen Sie jetzt fest, wann der Mitarbeiter für Online-Termine zur Verfügung steht. 
						Sie können entweder alle Arbeitszeiten online freigeben oder nur Teile davon.</p>`,
		buttons: [BUTTON_SKIP]
	},
	{
		// STEP: ADD ONLINE BOOKING HOURS
		step: 7,
		percent: 60,
		info: '4/5',
		title: {
			content: 'Einführung'
		},
		description: `Online-Freigaben`,
		detail: `<p>Gut gemacht! Sie können jetzt weitere Online-Freigaben hinzufügen. Andernfalls klicken Sie bitte hier im Fenster auf <strong>OK</strong>.</p>`,
		buttons: [BUTTON_SKIP,
			{
				type: 'mat-flat-button',
				color: 'primary',
				url: PAGE.PROVIDER_TREATMENT.fullpath,
				name: 'OK',
				stepUpdate: 8
			}
		]
	},
	{
		// STEP: TREATMENT
		step: 8,
		percent: 80,
		info: '5/5',
		title: {
			content: 'Einführung'
		},
		description: `Behandlungen`,
		detail: `<p>Legen Sie jetzt eine Behandlung an, die Ihre Patienten später beim Termin auswählen können. Klicken Sie dazu auf <strong>Behandlung hinzufügen</strong>.</p>`,
		buttons: [BUTTON_SKIP]
	},
	{
		// STEP: ADD TREATMENT
		step: 9,
		percent: 80,
		info: '5/5',
		title: {
			content: 'Einführung'
		},
		description: `Behandlungen`,
		detail: `<p>Geben Sie die erforderlichen Angaben ein.
						Fügen Sie anschließend die entsprechenden Mitarbeiter und Räume hinzu und klicken Sie dann auf <strong>Speichern</strong>.</p>`,
		buttons: [BUTTON_SKIP]
	},
	{
		// STEP: FINISH
		step: 10,
		info: '5/5',
		title: {
			content: 'Herzlichen Glückwunsch',
			color: 'accent'
		},
		icon: {
			name: 'check_circle_outline',
			zoom: '4',
			color: 'accent'
		},
		detail: `<p>Ihr Borino-Profil ist jetzt vollständig und Patienten können jetzt Termine buchen.</p>
				<p>Über das <strong>Kachel-Symbol</strong> unter Ihrem Profilbild finden Sie den Code, um das Buchungswidget auf Ihrer Webseite zu integrieren. 
					Sie finden dort auch den Link zu Ihrer persönlichen Profilseite. In der Navigation links finden Sie weitere Einstellungen für Ihre Profilseite.</p>`,
		buttons: [
			{
				type: 'mat-flat-button finish',
				color: 'primary',
				name: 'Tour beenden',
				stepUpdate: 1000
			}
		],
		hideMinimize: true
	}
];
