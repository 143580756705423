import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PAGE } from '@gp-angular/shared/schema';
import { isNullOrEmpty, ResourceUtils } from '@gp-angular/shared/utils';
import { AbstractComponent } from '@gp-angular/shared/abstract';
import { HashIdService } from '@gp-angular/service/hash-id';
import { ProviderService } from '@gp-angular/service/provider';
import { ResourceCategoryWrapperDTO, ResourceTypeEnumDTO } from '@noventi/gp-platform/care-providers';
import { withLatestFrom } from 'rxjs/operators';
import { UserService } from '@gp-angular/service/user';
import { UserRoleEnumDTO } from '@noventi/gp-platform/users';

@Component({
	selector: 'gp-provider-warnings',
	templateUrl: './warnings.component.html',
	styleUrls: ['./warnings.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProviderWarningsComponent extends AbstractComponent implements OnInit {

	public readonly PAGE = PAGE;

	public readonly ResourceUtils = ResourceUtils;

	public readonly ResourceTypeEnumDTO = ResourceTypeEnumDTO;

	public readonly isEmployee = this.userService.isRole(UserRoleEnumDTO.EMPLOYEE);

	public resourceCategoryList: Array<ResourceCategoryWrapperDTO>;

	public employeeResourceId: number;

	constructor(
		private providerService: ProviderService,
		private userService: UserService,
		private changeDetectorRef: ChangeDetectorRef,
		public hashIdService: HashIdService
	) {
		super();
	}

	ngOnInit(): void {
		super.addSubscription(this.providerService.readResources$()
			.pipe(
				withLatestFrom(this.userService.readManageResource$())
			)
			.subscribe(([next, manageResource]) => {
				if(this.isEmployee && !isNullOrEmpty(manageResource)) {
					this.employeeResourceId = +manageResource[0];
				}

				const RESOURCE_CATEGORY_LIST = next.filter(resourceCategory => !isNullOrEmpty(resourceCategory.items))
					.map(resourceCategory => ({
						...resourceCategory, items: resourceCategory.items.filter(resource => resource.treatments.length > 0 &&
																							  (!resource.hasBookingHours || !resource.hasOnlineBookingHours))
					}));

				if (!isNullOrEmpty(ResourceUtils.getAllResourceList(RESOURCE_CATEGORY_LIST))) {
					this.resourceCategoryList = RESOURCE_CATEGORY_LIST;
				} else {
					this.resourceCategoryList = [];
				}

				this.changeDetectorRef.markForCheck();
			})
		);
	}

}
