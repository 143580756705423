import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WEBSOCKET_FEATURE_KEY, State, WebsocketPartialState } from './websocket.reducer';

// Lookup the 'Websocket' feature state managed by NgRx
export const getWebsocketState = createFeatureSelector<WebsocketPartialState, State>(WEBSOCKET_FEATURE_KEY);

export const channelMap = createSelector(
	getWebsocketState,
	(state: State) => state.channelMap
);

export const list = createSelector(
	getWebsocketState,
	(state: State) => state.list
);

export const ignoreDeviceRevoke = createSelector(
	getWebsocketState,
	(state: State) => state.ignoreDeviceRevoke
);
