import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PAGE } from '@gp-angular/shared/schema';

@Injectable({
	providedIn: 'root'
})
export class RedirectProviderSyncOffice365Guard implements CanActivate {
	constructor(
		@Inject('ENVIRONMENT') private _environment: any,
		private router: Router
	) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const CODE = route.queryParams && route.queryParams.code ? route.queryParams.code : undefined;
		if (!!CODE) {
			this.router.navigate([PAGE.PROVIDER_SETTINGS_SYNC.fullpath], {queryParams: {oc: CODE}}).then();
		}
		return !!CODE;
	}

}
