<div class="row">
    <div class="col s12">
        <mat-card class="no-padding margin-vertical margin-horizontal-S">
            <nav mat-tab-nav-bar>
                <a mat-tab-link routerLinkActive #tab1="routerLinkActive" [active]="tab1.isActive"
                   [routerLink]="PAGE.PROVIDER_IMPRESSUM.path">
                    {{'Pages.Terms_Copyright.Label' | translate | uppercase}}
                </a>
                <a mat-tab-link routerLinkActive #tab2="routerLinkActive" [active]="tab2.isActive"
                   [routerLink]="PAGE.PROVIDER_PRIVACY.path">
                    {{'Pages.Terms_Privacy.Label' | translate | uppercase}}
                </a>
                <a mat-tab-link routerLinkActive #tab3="routerLinkActive" [active]="tab3.isActive"
                   [routerLink]="PAGE.PROVIDER_TERMSANDCONDITIONS.path">
                    {{'Pages.Terms_Condition.Label' | translate | uppercase}}
                </a>
            </nav>

            <mat-card-content>
                <router-outlet></router-outlet>
            </mat-card-content>
        </mat-card>
    </div>
</div>
