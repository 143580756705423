import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { PAGE } from '@gp-angular/shared/schema';

@Component({
  selector: 'gp-admin-account',
  templateUrl: './account.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminAccountComponent {

	public readonly PAGE = PAGE;

	constructor(
	) {
	}
}
