import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { AbstractComponent } from '@gp-angular/shared/abstract';
import { PAGE } from '@gp-angular/shared/schema';
import { ModalUtils, ProviderUtils } from '@gp-angular/shared/utils';
import { HashIdService } from '@gp-angular/service/hash-id';
import { ProviderService } from '@gp-angular/service/provider';
import { HistoryTypeEnumDTO, PersonDTO } from '@noventi/gp-platform/care-providers';
import { DialogService } from '@gp-angular/service/dialog';
import { UserService } from '@gp-angular/service/user';
import { AdminProviderHistoryComponent } from '../provider-history/provider-history.component';

@Component({
	selector: 'gp-admin-provider-detail-wrapper',
	templateUrl: './provider-detail-wrapper.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminProviderDetailWrapperComponent extends AbstractComponent implements OnInit {

	public readonly PAGE = PAGE;

	public readonly ProviderUtils = ProviderUtils;

	public permission$ = this.userService.getPermission$();

	public provider$: Observable<PersonDTO> = this.providerService.readProvider$()
		.pipe(
			filter((next) => !!next),
			distinctUntilChanged()
		);

	public url: string;

	public historyType: string;

	private static _historyExists(page: string): HistoryTypeEnumDTO {
		switch (page) {
			case PAGE.PROVIDER_INFORMATION.path:
				return HistoryTypeEnumDTO.PERSON;
			case PAGE.PROVIDER_SCHEDULE.path:
				return HistoryTypeEnumDTO.OPENHOURS;
			case PAGE.PROVIDER_SERVICE.path:
				return HistoryTypeEnumDTO.MEDICALSERVICE;
			case PAGE.PROVIDER_EXPERTISE.path:
				return HistoryTypeEnumDTO.EXPERTISEAREA;
			case PAGE.PROVIDER_EXTRA_INFO.path:
				return HistoryTypeEnumDTO.LANGUAGE;
			default:
				return undefined;
		}
	}

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private dialogService: DialogService,
		private hashIdService: HashIdService,
		private providerService: ProviderService,
		private route: ActivatedRoute,
		private router: Router,
		private userService: UserService
	) {
		super()
	}

	public ngOnInit(): void {
		if (!!(this.route.snapshot.params && this.route.snapshot.params.id)) {
			this.url = `/${PAGE.ADMIN.path}/${PAGE.ADMIN_PROVIDER.path}/${this.route.snapshot.params.id}/`;
		}

		this._setHistoryType();

		super.addSubscription(this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(() => this._setHistoryType())
		);
	}

	public history(): void {
		const CONFIG = ModalUtils.config('large');
		CONFIG.panelClass = ['full-screen-S'];
		CONFIG.data = {...CONFIG.data, historyType: this.historyType};
		this.dialogService.openDialog(AdminProviderHistoryComponent, CONFIG);
	}

	private _setHistoryType() {
		const HISTORY_PAGE = this.route.snapshot.children.length > 0 && this.route.snapshot.children[0].url.length > 0 ?
			this.route.snapshot.children[0].url[0].path : undefined;
		this.historyType = HISTORY_PAGE ?  AdminProviderDetailWrapperComponent._historyExists(HISTORY_PAGE) : undefined;
		this.changeDetectorRef.markForCheck();
	}
}
