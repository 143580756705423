import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PathService } from './path.service';

@NgModule({
	imports: [CommonModule]
})
export class ServicePathModule {
	static forRoot(): ModuleWithProviders<ServicePathModule> {
		return {
			ngModule: ServicePathModule,
			providers: [PathService]
		};
	}
}
