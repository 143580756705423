export interface WebsocketChannelMap {
	subscriptions: number;
	connecting: boolean;
}

export class WebsocketStateWrapper {
	constructor(public readonly url: string,
				public readonly opening = true,
				public readonly open = false,
				public readonly forceClosed = false) {
	}
}
