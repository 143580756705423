import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromWebsocket from './websocket.reducer';
import * as WebsocketSelectors from './websocket.selectors';

@Injectable()
export class WebsocketFacade {
	list$ = this.store.pipe(select(WebsocketSelectors.list));
	channelList$ = this.store.pipe(select(WebsocketSelectors.channelMap));
	ignoreDeviceRevoke$ = this.store.pipe(select(WebsocketSelectors.ignoreDeviceRevoke));

	constructor(private store: Store<fromWebsocket.WebsocketPartialState>) {
	}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}
}
