import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnInit, ViewChild, ViewEncapsulation
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { ToastContainerDirective } from 'ngx-toastr';
import { AbstractComponent } from '@gp-angular/shared/abstract';
import { PAGE } from '@gp-angular/shared/schema';
import { ApplicationService, MEDIA_QUERY_SIZE } from '@gp-angular/service/application';
import { MessageService } from '@gp-angular/service/message';
import { UserService } from '@gp-angular/service/user';

@Component({
	selector: 'gp-admin-template',
	templateUrl: './template.component.html',
	styleUrls: ['./template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class AdminTemplateComponent extends AbstractComponent implements OnInit {

	@HostBinding('class') class = 'template';

	@ViewChild('sidenav', {static: true}) public sidenav: MatSidenav;

	@ViewChild(ToastContainerDirective, {static: true}) toastContainer: ToastContainerDirective;

	public readonly PAGE = PAGE;

	public permission$ = this.userService.getPermission$();

	public name$: Observable<string> = this.userService.getAccountName$();

	public email$: Observable<string> = this.userService.getAccountEmail$();

	public sidenavMode: string;

	public env: { version: string, production: string };

	constructor(
		@Inject('ENVIRONMENT') private _environment: any,
		private applicationService: ApplicationService,
		private changeDetectorRef: ChangeDetectorRef,
		private messageService: MessageService,
		private userService: UserService
	) {
		super();

		this.env = {version: this._environment.version, production: this._environment.production};

		this.changeDetectorRef.markForCheck();
	}

	public ngOnInit(): void {
		super.addSubscription(this.applicationService.getMediaQuerySize$()
			.subscribe(value => {
				this.sidenavMode = value === MEDIA_QUERY_SIZE.SMALL ? 'over' : 'side';
				this.sidenav[value !== MEDIA_QUERY_SIZE.SMALL ? 'open' : 'close']();
				this.changeDetectorRef.markForCheck();
			}));
	}

	public logout(): void {
		this.userService.logout();
	}
}
