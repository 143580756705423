<mat-sidenav-container>
    <mat-sidenav #sidenav position="start" disableClose="true" [mode]="sidenavMode" color="primary">
        <ng-container [ngTemplateOutlet]="menu"></ng-container>
    </mat-sidenav>

    <!--    <mat-sidenav position="end">End</mat-sidenav>-->

    <mat-sidenav-content>
        <ng-container [ngTemplateOutlet]="header"></ng-container>
        <ng-content select="[template-loading]"></ng-content>
        <ng-content select="[template-content]"></ng-content>

        <router-outlet><!-- Here goes the magic navigation --></router-outlet>

        <ng-container [ngTemplateOutlet]="footer"></ng-container>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #menu>
    <mat-toolbar class="no-padding" [color]="sidenavMode === 'over' ? 'dark' : 'grey-dark'">
        <gp-angular-logo class="component-title hide-on-close" [ngClass]="{'light' : sidenavMode === 'over'}"></gp-angular-logo>

        <div class="element-spacer"></div>

        <button mat-mini-fab (click)="sidenav.toggle()" color="light" class="hide-on-small-and-down">
            <mat-icon>menu</mat-icon>
        </button>

        <button mat-icon-button (click)="sidenav.toggle()" class="hide-on-med-and-up">
            <mat-icon>clear</mat-icon>
        </button>
    </mat-toolbar>

    <mat-toolbar class="hide-on-med-and-up" color="dark">
        <div class="small">{{ (name$ | async) }}
            <div class="opacity-5">{{ (email$ | async) }}</div>
        </div>
    </mat-toolbar>

    <mat-toolbar class="flex-space-between hide-on-med-and-up" color="dark">
        <a class="small" [routerLink]="PAGE.PROVIDER_SETTINGS.fullpath">{{'Pages.Provider.User.Settings.Title' | translate}}</a>
        <a class="small" (click)="logout()">{{'Pages.Logout.Label' | translate}}</a>
    </mat-toolbar>


    <div class="scroll" *ngIf="(permission$ | async) as permission">
        <gp-provider-institution-select></gp-provider-institution-select>

        <mat-nav-list class="no-padding margin-bottom">
            <ng-container *ngIf="!employeeLimitedAccess; else limitedAccess">
                <a mat-list-item routerLinkActive="active-list-item"
                   *ngIf="permission.includes('CALENDAR_VIEW')"
                   [routerLink]="PAGE.PROVIDER_CALENDAR.fullpath"
                   (click)="sidenavMode === 'over' ? sidenav.toggle() : ''">
                    <mat-icon matSuffix>date_range</mat-icon>
                    <span class="mat-list-text hide-on-close">{{'Pages.Provider.Calendar.Label' | translate}}</span>
                </a>

                <a mat-list-item routerLinkActive="active-list-item"
                   *ngIf="permission.includes('MESSAGING_VIEW')"
                   [routerLink]="PAGE.PROVIDER_MESSAGE.fullpath"
                   (click)="sidenavMode === 'over' ? sidenav.toggle() : ''">
                    <mat-icon matSuffix>notifications</mat-icon>
                    <span class="mat-list-text hide-on-close">{{'Api.ProviderNotification.Title' | translate}}</span>
                    <span matBadgeColor="accent" [matBadge]="(countUnreadMessage$ | async) > 0 ? (countUnreadMessage$ | async) : ''"></span>
                </a>
                <a mat-list-item routerLinkActive="active-list-item"
                   *ngIf="permission.includes('INSTITUTION_SETTINGS_EDIT')"
                   [routerLink]="PAGE.PROVIDER_PATIENT_WAITING_LIST.fullpath"
                   (click)="sidenavMode === 'over' ? sidenav.toggle() : ''">
                    <mat-icon matSuffix>hourglass_empty</mat-icon>
                    <span class="mat-list-text hide-on-close">{{'Api.ProviderPatientWaitingList.Title' | translate}}</span>
                </a>
            </ng-container>
            <ng-template #limitedAccess>
                <a mat-list-item routerLinkActive="active-list-item" class="disabled"
                   [matTooltip]="'App.Tooltip.Resource_Unlinked_To_Employee_Account' | translate"
                   *ngIf="permission.includes('CALENDAR_VIEW')">
                    <mat-icon matSuffix>date_range</mat-icon>
                    <span class="mat-list-text hide-on-close">{{'Pages.Provider.Calendar.Label' | translate}}</span>
                </a>

                <a mat-list-item routerLinkActive="active-list-item" class="disabled"
                   [matTooltip]="'App.Tooltip.Resource_Unlinked_To_Employee_Account' | translate"
                   *ngIf="permission.includes('MESSAGING_VIEW')">
                    <mat-icon matSuffix>notifications</mat-icon>
                    <span class="mat-list-text hide-on-close">{{'Api.ProviderNotification.Title' | translate}}</span>
                </a>
            </ng-template>

            <ng-container *ngIf="permission.includes('PROFILE_DATA_VIEW')">
                <a mat-list-item [class.active-group]="activeRoute === 'profile'" (click)="activeSubmenu[0] = !activeSubmenu[0]" class="hide-on-close">
                    <mat-icon matSuffix>folder_open</mat-icon>
                    <span class="mat-list-text hide-on-close">{{'Pages.Provider.Information.Menu_Header' | translate}}</span>
                    <span matSuffix><mat-icon [ngClass]="{'rotate-270' : !activeSubmenu[0]}">keyboard_arrow_down</mat-icon></span>
                </a>
                <gp-provider-menu-aside class="hide-on-close" [ngClass]="{'hide' : !activeSubmenu[0]}"
                                        (click)="sidenavMode === 'over' ? sidenav.toggle() : ''">
                </gp-provider-menu-aside>

                <a mat-list-item routerLinkActive="active-group" [matMenuTriggerFor]="providerMenu" class="hide-on-open hide-on-small-and-down">
                    <mat-icon matSuffix>folder_open</mat-icon>
                </a>
                <mat-menu #providerMenu="matMenu" xPosition="after" class="aside-submenu" color="primary">
                    <gp-provider-menu-aside></gp-provider-menu-aside>
                </mat-menu>
            </ng-container>

            <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('PATIENT_MANAGEMENT_VIEW')"
               [routerLink]="PAGE.PROVIDER_PATIENT.fullpath">
                <mat-icon matSuffix>group</mat-icon>
                <span class="mat-list-text hide-on-close">{{'Api.ProviderPatientManagement.Title' | translate}}</span>
            </a>

            <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('USERS_MANAGEMENT_VIEW')"
               [routerLink]="PAGE.PROVIDER_USER_MANAGEMENT.fullpath">
                <mat-icon matSuffix>lock</mat-icon>
                <span class="mat-list-text hide-on-close">{{'Api.ProviderUserManagement.Title' | translate}}</span>
            </a>
        </mat-nav-list>

        <div class="element-spacer"></div>

        <mat-nav-list class="no-padding margin-bottom">
            <a mat-list-item [class.active-group]="activeRoute === 'help'" (click)="activeSubmenu[1] = !activeSubmenu[1]" class="hide-on-close">
                <mat-icon matSuffix>help</mat-icon>
                <span class="red-dot" *ngIf="newsList && newsList.length > 0"></span>
                <span class="mat-list-text hide-on-close"> {{'Pages.Provider.Help.Menu_Header' | translate}}</span>
                <span matSuffix><mat-icon [ngClass]="{'rotate-270' : !activeSubmenu[1]}">keyboard_arrow_down</mat-icon></span>
            </a>
            <div class="hide-on-close" [ngClass]="{'hide' : !activeSubmenu[1]}" (click)="sidenavMode === 'over' ? sidenav.toggle() : ''">
                <ng-container [ngTemplateOutlet]="helpMenuItems"></ng-container>
            </div>

            <a mat-list-item [class.active-group]="activeRoute === 'help'" [matMenuTriggerFor]="helpMenu" class="hide-on-open hide-on-small-and-down">
                <mat-icon matSuffix>help</mat-icon>
                <span class="red-dot" *ngIf="newsList && newsList.length > 0"></span>
            </a>
            <mat-menu #helpMenu="matMenu" xPosition="after" class="aside-submenu" color="primary">
                <ng-container [ngTemplateOutlet]="helpMenuItems"></ng-container>
            </mat-menu>

            <ng-template #helpMenuItems>
                <mat-nav-list class="submenu no-padding">
                    <a mat-list-item routerLinkActive="active-list-item" [routerLink]="PAGE.PROVIDER_FAQ.fullpath">
                        {{'Pages.FAQ.Label' | translate}}
                    </a>
                    <a mat-list-item routerLinkActive="active-list-item" [routerLink]="PAGE.PROVIDER_CONTACT.fullpath">
                        {{'Pages.Provider.Contact.Label' | translate}}
                    </a>
                    <a mat-list-item routerLinkActive="active-list-item" [routerLink]="'/' + PAGE.PROVIDER.path + '/' + PAGE.LEGAL.path">
                        {{'Pages.Provider.Legal.Title' | translate}}
                    </a>
                    <a mat-list-item routerLinkActive="active-list-item" [routerLink]="PAGE.PROVIDER_NEWS.fullpath" (click)="goToReleasePage()">
                        <span class="mat-list-text">
                            {{'Pages.Provider.News.Title' | translate}}
                            <span class="red-dot" *ngIf="newsList && newsList.length > 0"></span>
                        </span>
                    </a>
                </mat-nav-list>
            </ng-template>
        </mat-nav-list>

        <mat-hint *ngIf="!env.production" class="version margin">v {{env.version}}</mat-hint>
    </div>
</ng-template>

<ng-template #header>
    <mat-toolbar class="template-header" color="grey">
        <button mat-icon-button class="hide-on-med-and-up show-on-small" (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
            <span class="red-dot" *ngIf="newsList && newsList.length > 0"></span>
        </button>

        <div class="element-spacer"></div>

        <gp-angular-logo class="component-title hide-on-med-and-up"></gp-angular-logo>

        <ng-content header-content select="[template-header]"></ng-content>

        <div class="element-spacer"></div>

        <gp-provider-warnings></gp-provider-warnings>

        <button *ngIf="newsList && newsList.length > 0" mat-icon-button class="margin-left-S hide-on-med-and-down" (click)="goToReleasePage()">
            <mat-icon>new_releases</mat-icon>
            <span class="red-dot"></span>
        </button>

        <button class="user hide-on-small-and-down" mat-button [matMenuTriggerFor]="appMenu">
            <mat-icon>account_circle</mat-icon>
            <div class="margin-left-S text-trim">{{ (name$ | async) }}</div>
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
    </mat-toolbar>
</ng-template>

<ng-template #footer>
    <!--    <mat-toolbar class="template-footer" color="transparent">-->
    <ng-content footer-content select="[template-footer]"></ng-content>
    <!--    </mat-toolbar>-->
</ng-template>

<mat-menu #appMenu="matMenu">
    <button mat-menu-item color="primary" type="button" [routerLink]="PAGE.PROVIDER_SETTINGS_DATA.fullpath">
        {{'Pages.Provider.User.Settings.Title' | translate}}
    </button>

    <button mat-menu-item color="primary" type="button" *ngIf="(provider$ | async) as provider"
            (click)="widget({profileLink: provider.profileLink, widgetToken: provider.widgetToken})">
        {{'App.Widget_Integration' | translate}}
    </button>

    <button mat-menu-item color="primary" (click)="logout()">
        {{'Pages.Logout.Label' | translate}}
    </button>

</mat-menu>
