import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import localeDE from '@angular/common/locales/de';
import { MatIconRegistry } from '@angular/material/icon';
import { debounceTime, distinctUntilChanged, filter, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AbstractComponent } from '@gp-angular/shared/abstract';
import { PAGE } from '@gp-angular/shared/schema';
import { ModalUtils } from '@gp-angular/shared/utils';
import { AdminService } from '@gp-angular/service/admin';
import { ApplicationService } from '@gp-angular/service/application';
import { AnalyticsConstants, AnalyticsService } from '@gp-angular/service/analytics';
import { DialogContentComponent, DialogService } from '@gp-angular/service/dialog';
import { HashIdService } from '@gp-angular/service/hash-id';
import { AnimationFade, ApiKdsOnboardingService, AppTourActionType } from '@gp-angular/api-kds/onboarding';
import { MessageService } from '@gp-angular/service/message';
import { UserService } from '@gp-angular/service/user';
import { WebsocketService } from '@gp-angular/service/websocket';
import { PathService } from '@gp-angular/service/path';
import { ProviderService } from '@gp-angular/service/provider';
import { NotificationTypeEnumDTO, UserRoleEnumDTO } from '@noventi/gp-platform/users';
import { MessageStatusEnumDTO } from '@noventi/gp-platform/online-appointments';
import { AppProviderTour, AppProviderTourDropping } from './provider/_config/app-provider-tour.schema';

@Component({
	selector: 'gp-root',
	templateUrl: './app.component.html',
	animations: [AnimationFade],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.Emulated
})
export class AppComponent extends AbstractComponent implements OnInit, OnDestroy {

	private readonly _SWITCH_ACCOUNT_DELAY = 2000;

	public readonly AppProviderTour = AppProviderTour;

	public readonly AppProviderTourDropping = AppProviderTourDropping;

	public title = 'admin';

	public showProviderTour: boolean;

	public stepProviderTour: number;

	constructor(
		@Inject('ENVIRONMENT') private _environment: any,
		@Inject('stompConfig') private socket,
		private adminService: AdminService,
		private applicationService: ApplicationService,
		private analyticsService: AnalyticsService,
		private changeDetectorRef: ChangeDetectorRef,
		private dialogService: DialogService,
		private domSanitizer: DomSanitizer,
		private hashIdService: HashIdService,
		private onboardingService: ApiKdsOnboardingService,
		private pathService: PathService,
		private providerService: ProviderService,
		private router: Router,
		private matIconRegistry: MatIconRegistry,
		private messageService: MessageService,
		private userService: UserService,
		private translateService: TranslateService,
		private websocketService: WebsocketService
	) {
		super();

		this.matIconRegistry.addSvgIcon('gp-maximize', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/gp-maximize.svg'));
		this.matIconRegistry.addSvgIcon('gp-minimize', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/gp-minimize.svg'));
	}

	public ngOnInit(): void {
		registerLocaleData(localeDE);

		this.applicationService.initVersion();
		this.applicationService.initResize();
		this.analyticsService.init();
		this.pathService.init();

		super.addSubscription(this.userService.shouldLogout$()
			.pipe(
				filter((value) => !!value),
				switchMap(() => combineLatest([
					this.userService.clearState$(),
					this.adminService.clearState$(),
					this.onboardingService.clearState$(),
					this.providerService.clearState$(),
					this.websocketService.close$()
				])),
				filter(() => !!!this.userService.isLoggedIn()),
				tap(() => this.showProviderTour = false)
			)
			.subscribe(() => this.router.navigate([PAGE.LOGIN.path]).then(() => {
				this.messageService.clearToast();
			}))
		);

		super.addSubscription(this.applicationService.getVersionUpdateRequired$()
			.pipe(
				filter((value) => !!value)
			)
			.subscribe(() => {
				setTimeout(() => {
					const CONFIG = ModalUtils.config();
					CONFIG.disableClose = true;
					CONFIG.data = {
						...CONFIG.data,
						title: {
							default: this.translateService.instant('Pages.Refresh.Title')
						},
						content: this.translateService.instant('Pages.Refresh.Content',
							{appName: this.translateService.instant('App.Name')}),
						actions: [{
							title: this.translateService.instant('Pages.Refresh.Label'),
							color: 'primary',
							extraClass: 'mat-flat-button',
							closeButton: false
						}]
					};

					this.dialogService.openDialog(DialogContentComponent, CONFIG)
						.afterClosed().subscribe(() => window.location.reload());
				});
			}));


		super.addSubscription(this.userService.renewPasswordNeeded()
			.pipe(
				filter((next) => !!next && next <= 14)
			)
			.subscribe((next) => this.messageService.toastPED(next))
		);


		/**
		 * Apply permissions
		 * Open and listen to websocket
		 */
		super.addSubscription(this.userService.readUsername$()
			.pipe(
				filter((next) => !!next),
				//distinctUntilChanged(),
				tap(() => {
					this.showProviderTour = undefined;
					const PERMISSION = this.userService.getPermissions();
					switch (PERMISSION[0]) {
						case UserRoleEnumDTO.INSTITUTIONMANAGER:
						case UserRoleEnumDTO.OFFICEMANAGER:
							this.userService.getOnboarding();
							break;
						case UserRoleEnumDTO.EMPLOYEE:
						case UserRoleEnumDTO.SUPERADMIN:
						case UserRoleEnumDTO.ADMIN:
						case UserRoleEnumDTO.CUSTOMERCARE:
							break;
						default:
							break;
					}
				}),
				switchMap(() => this.websocketService.subscribeNotificationChannel$()
					.pipe(
						filter((next) => !!next)
					)
				),
				debounceTime(1)
			)
			.subscribe((next) => {
				if (next === NotificationTypeEnumDTO.NEWRELEASENOTES) {
					this.userService.loadNotificationList();
				} else if (next === 'REFRESH_TOKEN') {
					this.userService.refreshToken$();
				} else {
					// Already revoked
					this.userService.logout(false);
				}
			})
		);

		super.addSubscription(combineLatest([this.userService.getOnboarding$(), this.userService.loading$(),
			this.userService.getPermission$()])
			.pipe(
				tap(([next]) => {
					if (!next) {
						this.userService.setOnboarding(0);
					} else if (next.step === -100 && !this.showProviderTour) {
						this.showProviderTour = true;
						this.stepProviderTour = 1;
						this.userService.setOnboarding(1);
					}
				}),
				distinctUntilChanged((a, b) => JSON.stringify(a[0]) !== JSON.stringify(b[0])),
				filter(([next, loading, permission]) => !loading && !!next && permission.includes('VIEW_ONBOARDING_TOUR')),
				filter(([next]) => this.showProviderTour !== false && (-1 < next.step && next.step < 1000)),
				tap(() => this.showProviderTour = true),
			)
			.subscribe(([next]) => this.stepProviderTour = next.step)
		);

		super.addSubscription(combineLatest([this.userService.getCurrentPid$(), this.providerService.readProvider$()])
			.pipe(
				filter(([pid]) => !!pid),
				tap(([pid, provider]) => {
					if (!provider) {
						this.providerService.loadProvider$(pid);
					}
				}),
				distinctUntilChanged((a, b) => JSON.stringify(a[0]) === JSON.stringify(b[0])),
				switchMap(([pid]) => combineLatest([this.providerService.loadProvider$(pid), this.userService.getPermission$()])),
				filter(([, permission]) => permission.includes('MESSAGING_VIEW')),
				tap(() => {
					this.providerService.loadResources();
					this.providerService.countUnreadMessage$();
				}),
				debounceTime(this._SWITCH_ACCOUNT_DELAY),
				switchMap(() => this.websocketService.subscribeNotificationAppointment$(this.socket.channelMap.provider.messages)
					.pipe(
						filter((message) => !!message && !!message['status']),
						withLatestFrom(this.userService.getCurrentPid$()),
						tap(([message, pid]) => {
							if (+message['personId'] === +pid) {
								this.providerService.increaseCountUnreadMessage();
							}
							this._toastMessage(message['status'], message['practiceName'], message['personId']);
						})
					)
				)
			)
			.subscribe());

		super.addSubscription(combineLatest([this.userService.error$(), this.providerService.error$(), this.adminService.error$()])
			.pipe(
				filter(([userError, providerError, adminError]) =>
					(!!userError && !!userError.status) ||
					(!!providerError && !!providerError.status) ||
					(!!adminError && !!adminError.status)
				)
			)
			.subscribe(([userError, providerError, adminError]) => {
				const ERROR = [userError, providerError, adminError].find((e) => !!e && e.status);
				switch (ERROR.status) {
					case 400:
						if (ERROR.error.error === 'invalid_grant' && ERROR.error.error_description.indexOf('Invalid refresh token') > -1) {
							this.userService.logout(false);
							window.location.reload();
						}
						break;
					case 401:
						this.userService.logout(false);
						window.location.reload();
						break;
					case 403:
						if (ERROR.error.error === 'access_denied') {
							this.router.navigate([PAGE.ERROR.path]).then();
						}
						break;
					case 426:
					case 404:
					case 408:
					case 502:
					case 0:
						this.router.navigateByUrl(PAGE.ERROR.path).then();
						break;
					default:
					// Do nothing
				}
			})
		);
	}

	public ngOnDestroy() {
		super.ngOnDestroy();
		this.pathService.destroy();
	}

	/**
	 * Onboarding effects with other services
	 */
	public onboardingProvider(event) {
		switch (event.action) {
			case AppTourActionType.DISMISS:
				this._onboardingClose(event);
				this.userService.setOnboarding(event.step);
				break;
			case AppTourActionType.FORCE_CLOSE:
				this._onboardingClose(event);
				this.userService.setOnboarding(-1);
				break;
			case AppTourActionType.OUTPUT:
				if (event.output.value === 'next-step') {
					this.userService.setOnboarding(event.step);
					if (event.step === 2) {
						/** Navigate to resource after update is done... */
						super.addSubscription(this.providerService.loading$()
							.pipe(
								filter((next) => !next),
								take(1)
							)
							.subscribe(() => this._onboardingNavigate(event))
						);
					} else if (event.step === 4) {
						if (event.output.url) {
							/** Navigate to edit on an existing resource */
							setTimeout(() => this._onboardingNavigate(event), 1);
						} else {
							/** Navigate to edit after resource is created... */
							super.addSubscription(this.providerService.getCreateResource$()
								.pipe(
									filter((next) => !!next),
									take(1)
								)
								.subscribe((next) =>
									this._onboardingNavigate({
										...event, output: {
											...event.output,
											url: PAGE.PROVIDER_RESOURCE.fullpath + '/' + this.hashIdService.encode(next.id) + '/' + PAGE.RESOURCE_BUSINESS_HOURS.path
										}
									})
								)
							);
						}
					} else if (event.step === 10) {
						this._onboardingNavigate(event);
					}
				}
				break;
			case AppTourActionType.UPDATE:
				if (event.step === 0) {
					/** START */
					this.analyticsService.emitEvent(AnalyticsConstants.CATEGORY_ONBOARDING,
						AnalyticsConstants.ACTION_ONBOARDING_TOUR_START);
				} else if (event.step === 6) {
					const PATH = window.location.pathname;
					if (PATH.includes(PAGE.PROVIDER_RESOURCE.fullpath)) {
						const ARRAY_PATH = PATH.split('/');
						const ID = ARRAY_PATH.filter((val) => !!val).length - 1;
						if (typeof this.hashIdService.decode(ARRAY_PATH[ID]) === 'number') {
							this.router.navigate([`${PAGE.PROVIDER_RESOURCE.fullpath}/${ARRAY_PATH[ID]}/${PAGE.RESOURCE_ONLINE_APPOINTMENTS.path}`]).then();
						} else {
							this.router.navigate([PAGE.PROVIDER_RESOURCE.fullpath]).then();
						}
					}
				} else if (event.step === 1000) {
					this.showProviderTour = false;
					/** FINISHED */
					this.analyticsService.emitEvent(AnalyticsConstants.CATEGORY_ONBOARDING,
						AnalyticsConstants.ACTION_ONBOARDING_TOUR_FINISH);
				}
				this.userService.setOnboarding(event.step);
				break;
			default:
				// TODO: do something?!...
				break;
		}
	}

	/** Onboarding close */
	private _onboardingClose(event) {
		this.stepProviderTour = -1;
		this.showProviderTour = false;
		const EVENT_LABEL = event.step < 0 ? `(Quit at step ${event.step})` : `(Reload from step ${event.step})`;
		this.analyticsService.emitEvent(AnalyticsConstants.CATEGORY_ONBOARDING,
			AnalyticsConstants.ACTION_ONBOARDING_TOUR_DROP, EVENT_LABEL);
	}

	/** Onboarding navigation triggered by interact with application */
	private _onboardingNavigate(event): void {
		this.router.navigate([event.output.url]).then(() => this.stepProviderTour = +event.step);
	}

	/** Notification */
	private _toastMessage(status: MessageStatusEnumDTO, name: string, pid: number): void {
		super.addSubscription(this.messageService.toastNewProviderMessage$(status, name, pid)
			.pipe(
				withLatestFrom(this.userService.getCurrentPid$()),
				take(1),
				tap(([toast, currentPid]: [{ clicked: boolean, pid: number }, number]) => {
					if (toast.clicked && currentPid !== toast.pid) {
						this.userService.setCurrentPid(toast.pid);
					}
				}),
				debounceTime(+(this._SWITCH_ACCOUNT_DELAY + 100))
			)
			.subscribe(() => this.router.navigate([PAGE.PROVIDER_MESSAGE.fullpath]).then()));
	}
}
