import {
	Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, OnDestroy, Output, EventEmitter, HostBinding
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { AppTourActionType, AppTourDismissButtonDTO, AppTourDismissDTO, AppTourDTO } from '../api-kds-onboarding.schema';
import { ApiKdsOnboardingService } from '../api-kds-obboarding.service';
import { AnimationSizeChange, AnimationStepChange } from '../api-kds-onboarding.animation';

@Component({
	selector: 'app-kds-onboarding-tour',
	templateUrl: './onboarding-tour.component.html',
	styleUrls: ['./onboarding-tour.component.scss', './_custom_switch.scss', './_custom-circle-percentage.scss'],
	animations: [AnimationStepChange, AnimationSizeChange],
	encapsulation: ViewEncapsulation.Emulated,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnboardingTourComponent implements OnInit, OnDestroy {

	private _skipEmit: boolean;

	private _tourDisabled: boolean;

	private _subscription1$: Subscription;

	private _eventListenerArray: NodeListOf<any>;

	@Output() public changes: EventEmitter<{ action: AppTourActionType, step: number, output?: { value: string, url: string } }> =
		new EventEmitter<{ action: AppTourActionType, step: number, output?: { value: string, url: string } }>();

	@Input() public stepArray: Array<AppTourDTO>;

	@Input() public drop: AppTourDismissDTO;

	@Input()
	public set step(value: number) {
		if (value !== undefined) {
			this.onboardingService.setStep(+value)
		}
	}

	@HostBinding('class') get HeadingClass() {
		let CLASS = '';
		CLASS += this.overlay ? ' app-kds-onboarding-tour-overlay' : '';
		CLASS += this.minimize ? ' minimize' : '';
		return CLASS;
	}

	public overlay: boolean;

	public minimize: boolean;

	public showDrop: boolean;

	public current: number;

	public process: number;

	public reload = true;

	constructor(
		private onboardingService: ApiKdsOnboardingService,
		private router: Router
	) {
	}

	public ngOnInit(): void {
		const STYLE = document.createElement('style');
		STYLE.innerText = '.tour-active-hidden {visibility: hidden}';
		STYLE.setAttribute('id', 'onboarding-tour');
		document.head.appendChild(STYLE)

		if (document.body) {
			document.body.classList.add('onboarding-mobile-padding');
		}

		this._subscription1$ = this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(() => setTimeout(() => this._output(), 1000));

		this._subscription1$ = this.onboardingService.step$()
			.pipe(
				filter(() => !this._tourDisabled),
				tap((next) => {
					if (next === undefined) {
						/** Not initialized */
						this.onboardingService.setStep(0);
					}
					if ((next >= this.stepArray.length || next === -1) && next < 1000) {
						/** No slot found into array, will auto-close tour! */
						this._forceClose();
					}
					if (next === 1000) {
						document.body.classList.remove('onboarding-mobile-padding');
					}
				}),
				distinctUntilChanged(),
				filter((next) => !this._tourDisabled && next !== undefined),
			)
			.subscribe((next) => {
				this.current = next;
				this.overlay = !!this.stepArray[next] && !!this.stepArray[next].overlay;
				if (!this._skipEmit) {
					this.changes.emit({action: AppTourActionType.UPDATE, step: next});
				} else {
					setTimeout(() => this._output());
					this._skipEmit = false;
				}
			})
	}

	public ngOnDestroy(): void {
		this._subscription1$.unsubscribe();
	}

	public action(button: AppTourDismissButtonDTO): void {
		if (this.drop && this.showDrop) {
			if (button.drop) {
				if (this.reload) {
					this._close();
				} else {
					this._forceClose();
				}
			} else if (button.revert) {
				this.showDrop = false;
				this.reload = true;
				this.overlay = this.stepArray[this.current].overlay;
			}
		}
		if (button.close) {
			if (this.drop && !this.showDrop) {
				this.showDrop = true;
				this.overlay = this.drop.overlay ? this.drop.overlay : false;
			} else {
				this.changes.emit({action: AppTourActionType.DISMISS, step: this.current});
				this.showDrop = false;
			}
		}

		if (button.stepUpdate) {
			this.onboardingService.setStep(button.stepUpdate);
		}

		if (button.url) {
			this.router.navigate([button.url]).then();
		}
	}

	private _output(): void {
		if (!!this._eventListenerArray && this._eventListenerArray.length > 0) {
			this._eventListenerArray.forEach(el => {
				el.removeEventListener('click', () => ({}));
			})
		}
		this._eventListenerArray = document.querySelectorAll('[data-tour-output]');
		this._eventListenerArray.forEach(el => {
			el.addEventListener('click', () => {
				if (el.className.indexOf('disabled') < 0 && el.attributes['data-tour-output']) {
					const OUTPUT = el.getAttribute('data-tour-output');
					const STEP = +el.getAttribute('data-tour-output-step');
					const CALLBACK = el.getAttribute('data-tour-output-callback');

					this.minimize = false;

					if (OUTPUT === 'next-step') {
						if (STEP) {
							this.onboardingService.setStep(STEP);
						}
					} else if (OUTPUT === 'next-step-virtual-only') {
						this._skipEmit = true;
						this.current = STEP ? STEP : +this.current + 1;
						setTimeout(() => {
							this._output();
						})
					}

					this.changes.emit({
						action: AppTourActionType.OUTPUT, step: +this.current, output: {value: OUTPUT, url: CALLBACK}
					});
				}
			});
		});
		document.querySelectorAll('[data-tour-hide]').forEach(el => this._toggle(el, true));
	}

	private _toggle(el, show: boolean = false): void {
		try {
			if (show) {
				const HIDE_STEP = el.getAttribute('data-tour-hide');
				if (HIDE_STEP && +HIDE_STEP === +this.current) {
					el.classList.add('tour-active-hidden');
				}
			} else {
				el.classList.remove('tour-active-hidden');
				document.getElementById('onboarding-tour').remove();
			}
		} catch (e) {
			console.warn('Onboarding Module cound not find element:', el);
		}
	}

	private _close(): void {
		this.showDrop = false;
		this.changes.emit({action: AppTourActionType.DISMISS, step: this.current});
		this.onboardingService.setStep(-1);
		this._tourDisabled = true;

		document.querySelectorAll('[data-tour-hide]').forEach(el => this._toggle(el));
		document.body.classList.remove('onboarding-mobile-padding');
	}

	private _forceClose(): void {
		this.showDrop = false;
		this.changes.emit({action: AppTourActionType.FORCE_CLOSE, step: this.current});
		this.onboardingService.setStep(-1);
		this._tourDisabled = true;

		document.body.classList.remove('onboarding-mobile-padding');
	}
}
