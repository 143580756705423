export interface AppTourDTO {
	step: number;
	percent?: number;
	overlay?: boolean;
	info?: string;
	icon?: {name: string, zoom?: string, color?: string};
	title?: {content: string, color?: string};
	description?: string;
	detail?: string;
	buttons?: Array<AppTourButtonDTO>;
	output?: {value: string, url: string};
	hideMinimize?: boolean;
}

export interface AppTourDismissDTO extends AppTourDTO {
	reload?: string;
	buttons?: Array<AppTourDismissButtonDTO>;
}

export interface AppTourDismissButtonDTO extends AppTourButtonDTO {
	/** Confirm the dismiss */
	drop?: boolean;
	/** Revert the dismiss */
	revert?: boolean;
}

export interface AppTourButtonDTO {
	/**
	 * Type of material button:
	 * Supported: mat-button | mat-raised-button | mat-flat-button| mat-stroked-button
	 * Unsupported: mat-icon-button | mat-fab | mat-mini-fab
	 */
	type?: string;
	name: string;
	color?: string;
	/**  Trigger and allow navigation from tour  */
	url?: string;
	/** Trigger the dismiss */
	close?: boolean;
	/** Update step state */
	stepUpdate?: number;
}

export type AppTourActionType = 'FORCE_CLOSE' | 'DISMISS' | 'UPDATE';

export const AppTourActionType = {
	FORCE_CLOSE: 'FORCE_CLOSE' as AppTourActionType,
	DISMISS: 'DISMISS' as AppTourActionType,
	UPDATE: 'UPDATE' as AppTourActionType,
	OUTPUT: 'OUTPUT' as AppTourActionType
};
