import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as WebsocketActions from './websocket.actions';
import { WebsocketChannelMap, WebsocketStateWrapper } from './websocket.models';
import { InternalUtils } from '../internal.utils';

export const WEBSOCKET_FEATURE_KEY = 'websocket';

export interface State extends EntityState<{}> {
	list: WebsocketStateWrapper[];
	channelMap: { [key: string]: WebsocketChannelMap };
	ignoreDeviceRevoke: boolean;
	error?: HttpErrorResponse | null
}

export interface WebsocketPartialState {
	readonly [WEBSOCKET_FEATURE_KEY]: State;
}

export const websocketAdapter: EntityAdapter<State> = createEntityAdapter<State>();

export const initialState: State = websocketAdapter.getInitialState({
	list: [],
	channelMap: {},
	ignoreDeviceRevoke: undefined,
	error: undefined
});

const websocketReducer = createReducer(
	initialState,

	on(WebsocketActions.Open, (state, {payload: {socketUrl}}) => ({
		...state,
		...InternalUtils.updateStateList(state.list, socketUrl, true, false)
	})),
	on(WebsocketActions.OpenComplete, (state, {payload}) => ({
		...state,
		...InternalUtils.updateStateList(state.list, payload, false, true)
	})),
	on(WebsocketActions.OpenFailedAction, (state, {payload}) => ({
		...state,
		...InternalUtils.updateStateList(state.list, payload, false, false)
	})),


	on(WebsocketActions.SubscribeChannel, (state, {payload: {socketUrl, channelUrl}}) => ({
		...state,
		...InternalUtils.updateChannelMap(state.channelMap, socketUrl + channelUrl)
	})),
	on(WebsocketActions.SubscribeChannelComplete, (state, {payload: {socketUrl, channelUrl}}) => ({
		...state,
		...InternalUtils.updateChannelMap(state.channelMap, socketUrl + channelUrl)
	})),
	on(WebsocketActions.SubscribeChannelFailed, (state, {error}) => ({...state, error})),
	/** NOT implemented */
	// on(WebsocketActions.UnsubscribeChannel, (state: State, {payload: {socketUrl, channelUrl}}) => ({
	// 	...state,
	// 	...WebsocketUtils.unsubscribeChannelMap(state.channelMap, channelUrl)
	// })),

	on(WebsocketActions.IgnoreDeviceRevoke, (state: State, {ignoreDeviceRevoke}) => ({...state, ignoreDeviceRevoke})),

	on(WebsocketActions.CloseComplete, () => ({...initialState}))
);

export function reducer(state: State | undefined, action: Action) {
	return websocketReducer(state, action);
}
