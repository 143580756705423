import { Inject, Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, delay, exhaustMap, filter, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { EMPTY, from, merge, of } from 'rxjs';

import * as WebsocketActions from './websocket.actions';
import { WebsocketFacade } from './websocket.facade';
import { HttpXsrfTokenExtractor } from '@angular/common/http';
import { InternalUtils } from '../internal.utils';
import { StompService } from '../stomp.service';
import { StompState } from '../stomp/stomp.models';

@Injectable()
export class WebsocketEffects {
	private stompServiceList: StompService[] = [];

	open$ = createEffect(() => this.actions$.pipe(
		ofType(WebsocketActions.Open),
		exhaustMap(({payload: {socketUrl}}) => {
			const stompService = InternalUtils.getService(this.stompServiceList, socketUrl);
			const currentState = stompService.state.getValue();

			/**
			 * Open is triggered by subscribe to channel
			 * this should return while 'open' or 'opening'
			 */
			if (stompService.connected() || currentState === StompState.OPEN || currentState === StompState.OPENING) {
				return EMPTY;
			}

			const openForced$ = of(WebsocketActions.Open({payload: {socketUrl: socketUrl}}))
				.pipe(
					delay(5000),
					filter(() => stompService.state.getValue() === StompState.FORCE_CLOSED)
				);

			return stompService.connect().pipe(
				take(1),
				switchMap(() => {
					return stompService.state.pipe(
						filter((state) => state !== StompState.OPENING),
						take(1),
						switchMap((state) => {
							if (state === StompState.OPEN) {
								const forceClosed$ = stompService.state.pipe(
									filter((next) => next !== StompState.OPEN),
									take(1),
									filter((next) => next === StompState.FORCE_CLOSED),
									switchMap(() => of(WebsocketActions.CloseAction({payload: {forceClose: true}})))
								);
								return merge(
									of(WebsocketActions.OpenComplete({payload: socketUrl})),
									openForced$,
									forceClosed$
								);
							} else {
								return merge(
									of(WebsocketActions.OpenFailedAction({payload: socketUrl})),
									openForced$
								);
							}
						})
					);
				})
			);
		})
		),
		{useEffectsErrorHandler: true}
	);

	subscribeChannel$ = createEffect(() => this.actions$.pipe(
		ofType(WebsocketActions.SubscribeChannel),
		withLatestFrom(this.websocketFacade.channelList$),
		filter(([{payload: {socketUrl, channelUrl}}, channelMap]) =>
			InternalUtils.isChannelOpened(channelMap[`${socketUrl}${channelUrl}`])),
		switchMap(([{payload: {socketUrl, channelUrl}}]) => {
			const stompService = InternalUtils.getService(this.stompServiceList, socketUrl);
			const actionList = [];

			if (InternalUtils.isWSOpen(stompService.state.getValue())) {
				actionList.push(WebsocketActions.Open({payload: {socketUrl: socketUrl}}));
			}

			// TODO: define subscription failed, maybe wait for open complete
			actionList.push(WebsocketActions.SubscribeChannelComplete({payload: {socketUrl: socketUrl, channelUrl: channelUrl}}));

			return merge(from(actionList), InternalUtils.subscribeChannel(stompService, this.actions$, channelUrl));

		}),
		catchError((error) => {
			return of(WebsocketActions.SubscribeChannelFailed({error}));
		})),
		{useEffectsErrorHandler: true}
	);

	listenChannel$ = createEffect(() => this.actions$.pipe(
		ofType(WebsocketActions.MessageReceived),
		filter(({payload: {channelUrl, body}}) => channelUrl === this.socket.channelMap.provider.callbacks && !!body),
		withLatestFrom(this.websocketFacade.ignoreDeviceRevoke$),
		filter(([{payload: {channelUrl, body}}, ignoreNext]) => !ignoreNext || body !== 'REVOKE_OTHER_SESSIONS'),
		switchMap(([{payload: {channelUrl, body}}, ignoreNext]) => of(WebsocketActions.UpdateMessage({payload: body})))
		),
		{useEffectsErrorHandler: true}
	);

	// TODO: Div review
	subscribeChannelAppointment$ = createEffect(() => this.actions$.pipe(
		ofType(WebsocketActions.SubscribeChannelAppointment),
		withLatestFrom(this.websocketFacade.channelList$),
		filter(([{payload: {socketUrl, channelUrl}}, channelMap]) =>
			InternalUtils.isChannelOpened(channelMap[`${socketUrl}${channelUrl}`])),
		switchMap(([{payload: {socketUrl, channelUrl}}]) => {
			const stompService = InternalUtils.getService(this.stompServiceList, socketUrl);
			const actionList = [];

			if (InternalUtils.isWSOpen(stompService.state.getValue())) {
				actionList.push(WebsocketActions.Open({payload: {socketUrl: socketUrl}}));
			}

			// TODO: define subscription failed, maybe wait for open complete
			actionList.push(WebsocketActions.SubscribeChannelComplete({payload: {socketUrl: socketUrl, channelUrl: channelUrl}}));

			return merge(from(actionList), InternalUtils.subscribeChannel(stompService, this.actions$, channelUrl));

		}),
		catchError((error) => {
			return of(WebsocketActions.SubscribeChannelFailed({error}));
		})),
		{useEffectsErrorHandler: true}
	);

	// TODO: Div review
	listenChannelAppointment$ = createEffect(() => this.actions$.pipe(
		ofType(WebsocketActions.MessageReceived),
		filter(({payload: {channelUrl, body}}) => channelUrl === this.socket.channelMap.provider.messages && !!body),
		withLatestFrom(this.websocketFacade.ignoreDeviceRevoke$),
		//filter(([{payload: {channelUrl, body}}, ignoreNext]) => !ignoreNext || body !== 'REVOKE_OTHER_SESSIONS'),
		switchMap(([{payload: {channelUrl, body}}, ignoreNext]) => of(WebsocketActions.UpdateMessageAppointment({payload: body})))
		),
		{useEffectsErrorHandler: true}
	);

	websocketClose$ = createEffect(() => this.actions$.pipe(
		ofType(WebsocketActions.CloseAction),
		exhaustMap(({payload: {forceClose, url}}) => {
			const stompService = InternalUtils.getService(this.stompServiceList, url ? url : this.socket.brokerURL);

			if (!!stompService) {
				if (!forceClose) { // This will close and not reconnect!
					stompService.disconnect();
				} else {
					stompService.state.next(StompState.FORCE_CLOSED);
				}
			}

			return of(WebsocketActions.CloseComplete());
		})
		),
		{useEffectsErrorHandler: true}
	);

	constructor(
		private actions$: Actions,
		private websocketFacade: WebsocketFacade,
		private stompService: StompService,
		private tokenExtractor: HttpXsrfTokenExtractor,
		@Inject('stompConfig') private socket
	) {
		this.stompServiceList.push(new StompService(socket, tokenExtractor));
	}
}
