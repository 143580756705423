import { WebsocketChannelMap, WebsocketStateWrapper } from './+state/websocket.models';
import { StompState } from './stomp/stomp.models';
import { StompService } from './stomp.service';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import * as WebsocketActions from './+state/websocket.actions';
import { Observable, of } from 'rxjs';

export class InternalUtils {

	private static _stateList(list: WebsocketStateWrapper[], channel: WebsocketStateWrapper, opening: boolean = false, open: boolean = false):
		{ list: WebsocketStateWrapper[] } {
		const index = list.findIndex(websocket => websocket.url === channel.url);
		return {
			list: [
				...list.slice(0, index),
				new WebsocketStateWrapper(channel.url, opening, open, channel.forceClosed),
				...list.slice(index + 1)
			]
		};
	}

	public static isWSOpen(state: StompState): boolean {
		return !!(state !== StompState.OPEN && state !== StompState.OPENING);
	}

	public static isChannelOpened(channel: WebsocketChannelMap): boolean {
		return !channel || (!channel.connecting && channel.subscriptions === 0);
	}

	public static getService(serviceList: Array<StompService>, url: string): StompService {
		return serviceList.find((service) => !!service && service.config.brokerURL === url);
	}

	public static updateStateList(list: WebsocketStateWrapper[], channel: string, opening: boolean = false, open: boolean = false):
		{ list: WebsocketStateWrapper[] } {
		const index = list.findIndex(websocket => websocket.url === channel);

		if (index < 0) {
			return {list: [...list, new WebsocketStateWrapper(channel)]};
		}

		const newSocket = list[index];
		if (newSocket.open) {
			return {list: [...list]};
		}

		return this._stateList(list, newSocket, opening, open);
	}

	public static updateChannelMap(channelMap: { [key: string]: WebsocketChannelMap }, key: string):
		{ channelMap: { [key: string]: WebsocketChannelMap } } {

		const keyChannelMap: WebsocketChannelMap = !!channelMap[key] ?
			{subscriptions: channelMap[key].subscriptions, connecting: true} :
			{subscriptions: 0, connecting: false};

		return {channelMap: {...channelMap, ...{[key]: keyChannelMap}}};
	}

	public static removeChannelMap(channelMap: { [key: string]: WebsocketChannelMap }, key: string):
		{ channelMap: { [key: string]: WebsocketChannelMap } } {

		if (!!channelMap[key] || channelMap[key].subscriptions > 0) {
			const newChannelMap = Object.assign({}, channelMap);
			delete newChannelMap[key];
			return {channelMap: newChannelMap};
		}

		return {channelMap: channelMap};
	}

	/** NOT implemented */
	// public static unsubscribeChannelMap(channelMap: { [key: string]: WebsocketChannelMap }, key: string):
	// 	{ channelMap: { [key: string]: WebsocketChannelMap } } {

	// 	if (!channelMap[key] || channelMap[key].subscriptions <= 1) {
	// 		const newChannelMap = Object.assign({}, channelMap);
	// 		delete newChannelMap[key];
	// 		return {channelMap: newChannelMap};
	// 	}

	// 	return {
	// 		channelMap: {
	// 			...channelMap, ...{
	// 				[key]: {
	// 					subscriptions: channelMap[key].subscriptions - 1,
	// 					connecting: false
	// 				}
	// 			}
	// 		}
	// 	};
	// }

	public static subscribeChannel(stompService, actions$, channelUrl): Observable<any> {
		return stompService.subscribe(channelUrl)
			.pipe(
				filter((next) => !!next),
				takeUntil(actions$.pipe(ofType(WebsocketActions.CloseComplete))),
				map((next: any) =>
					WebsocketActions.MessageReceived({payload: {channelUrl: channelUrl, body: JSON.parse(next.body)}})
				),
				catchError((error) => {
					return of(WebsocketActions.SubscribeChannelFailed({error}));
				})
			);
	}

	public static replaceParams(text: string, params?: { [key: string]: string }) {
		if (!params) {
			return text;
		}
		const keys = Object.keys(params);
		keys.forEach(key => {
			text = text.replace('${' + key + '}', params[key]);
		});
		return text;
	}
}
