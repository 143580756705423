import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnInit, ViewChild, ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { ToastContainerDirective } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AbstractComponent } from '@gp-angular/shared/abstract';
import { isNullOrEmpty, ModalUtils } from '@gp-angular/shared/utils';
import { PAGE } from '@gp-angular/shared/schema';
import { ApplicationService, MEDIA_QUERY_SIZE } from '@gp-angular/service/application';
import { DialogService, DialogWidgetComponent } from '@gp-angular/service/dialog';
import { MessageService } from '@gp-angular/service/message';
import { ProviderService } from '@gp-angular/service/provider';
import { UserService } from '@gp-angular/service/user';
import { PersonDTO } from '@noventi/gp-platform/care-providers';
import { NotificationDTO, NotificationTypeEnumDTO, UserRoleEnumDTO } from '@noventi/gp-platform/users';

@Component({
	selector: 'gp-provider-template',
	templateUrl: './template.component.html',
	styleUrls: ['./template.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProviderTemplateComponent extends AbstractComponent implements OnInit {

	@HostBinding('class') class = 'template';

	@ViewChild('sidenav', {static: true}) public sidenav: MatSidenav;

	@ViewChild(ToastContainerDirective, {static: true}) toastContainer: ToastContainerDirective;

	public readonly PAGE = PAGE;

	public permission$ = this.userService.getPermission$().pipe(
		/** Double check to be sure we remove loading from user */
		tap(() => this.userService.loading(false))
	);

	public name$: Observable<string> = this.userService.getAccountName$();

	public email$: Observable<string> = this.userService.getAccountEmail$();

	public countUnreadMessage$: Observable<number> = this.providerService.readCountUnreadMessage$();

	public provider$: Observable<PersonDTO> = this.providerService.readProvider$();

	public sidenavMode: MatDrawerMode;

	public env: { version: string, production: string };

	public employeeLimitedAccess: boolean;

	public activeRoute: string;

	public activeSubmenu: Array<boolean> = [false, false];

	public newsList: Array<NotificationDTO>;

	constructor(
		@Inject('ENVIRONMENT') private _environment: any,
		private applicationService: ApplicationService,
		private changeDetectorRef: ChangeDetectorRef,
		private dialogService: DialogService,
		private messageService: MessageService,
		private providerService: ProviderService,
		private route: ActivatedRoute,
		private router: Router,
		private userService: UserService,
		private translateService: TranslateService,
	) {
		super();

		this.env = {version: this._environment.version, production: this._environment.production};

		this.changeDetectorRef.markForCheck();
	}

	public ngOnInit() {
		this._setActiveRoute();

		this.userService.loadNotificationList();
		super.addSubscription(this.userService.getNotificationList$()
			.subscribe((next) => {
				this.newsList = next.filter((item) => item.type === NotificationTypeEnumDTO.NEWRELEASENOTES);
				this.changeDetectorRef.markForCheck();
			})
		);
		if (this.userService.isRole(UserRoleEnumDTO.EMPLOYEE)) {
			super.addSubscription(this.userService.readManageResource$()
				.subscribe((next) => this.employeeLimitedAccess = isNullOrEmpty(next))
			);
		}

		super.addSubscription(this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd && !!this.router.getCurrentNavigation().finalUrl)
			).subscribe(() => this._setActiveRoute())
		);

		super.addSubscription(this.applicationService.getMediaQuerySize$()
			.subscribe(value => {
				this.sidenavMode = value === MEDIA_QUERY_SIZE.SMALL ? 'over' : 'side';
				this.sidenav[value !== MEDIA_QUERY_SIZE.SMALL ? 'open' : 'close']();
				this.changeDetectorRef.markForCheck();
			}));
	}

	public widget(data: {}) {
		this.dialogService.openDialog(DialogWidgetComponent, {...ModalUtils.config(), data: data})
			.componentInstance
			.onToast
			.subscribe((toast) => {
				if (toast.type === 'success') {
					this.messageService.toastSuccess(this.translateService.instant(toast.content));
				} else if (toast.type === 'error') {
					this.messageService.toastError(this.translateService.instant(toast.content));
				}
			});
	}

	public logout(): void {
		this.userService.logout();
	}

	public goToReleasePage(): void {
		if (isNullOrEmpty(this.newsList)) {
			return;
		}
		this.router.navigate([PAGE.PROVIDER_NEWS.fullpath]).then(() =>
			super.addSubscription(this.userService.markNotificationAsRead$(this.newsList[0].id)
				.pipe(take(1))
				.subscribe(() => this._setActiveRoute())
			)
		);
	}

	private _setActiveRoute(): void {
		switch (this.route.snapshot.firstChild.url[0].path) {
			case PAGE.PROVIDER_PROFILE.path:
			case PAGE.PROVIDER_RESOURCE.path:
			case PAGE.PROVIDER_TREATMENT.path:
				this.activeSubmenu[0] = true;
				this.activeRoute = 'profile';
				break;
			case PAGE.FAQ.path:
			case PAGE.CONTACT.path:
			case PAGE.LEGAL.path:
			case PAGE.PROVIDER_NEWS.path:
				this.activeSubmenu[1] = true;
				this.activeRoute = 'help';
				break;
			default:
				this.activeRoute = undefined;
				break;
		}
	}
}
