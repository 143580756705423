import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Message } from '@stomp/stompjs';

export const Open = createAction(
	'[Websocket] Open',
	props<{ payload: {socketUrl: string} }>()
);

export const OpenComplete = createAction(
	'[Websocket] Open Complete',
	props<{ payload: any }>()
);

export const OpenFailedAction = createAction(
	'[Websocket] Open Failed',
	props<{ payload: string }>()
);

export const SubscribeChannel = createAction(
	'[Websocket] Subscribe Channel',
	props<{ payload: { socketUrl: string, channelUrl: string } }>()
);

export const SubscribeChannelAppointment = createAction(
	'[Websocket] Subscribe Channel Appointment',
	props<{ payload: { socketUrl: string, channelUrl: string } }>()
);

export const SubscribeChannelComplete = createAction(
	'[Websocket] Subscribe Channel Complete',
	props<{ payload: { socketUrl: string, channelUrl: string } }>()
);

export const SubscribeChannelFailed = createAction(
	'[Websocket] Subscribe Channel Failed',
	props<{ error: HttpErrorResponse }>()
);

export const MessageReceived = createAction(
	'[Websocket] Message Received',
	props<{ payload: { channelUrl: string, body: Message | {} } }>()
);

/** NOT implemented */
// export const UnsubscribeChannel = createAction(
// 	'[Websocket] Unsubscribe Channel',
// 	props<{ payload: { socketUrl: string, channelUrl: string } }>()
// );

// export const UnsubscribeChannelComplete = createAction(
// 	'[Websocket] Unsubscribe Channel Complete',
// 	props<{ payload: { socketUrl: string, channelUrl: string } }>()
// );

// export const UnsubscribeChannelFailed = createAction(
// 	'[Websocket] Unsubscribe Channel Failed',
// 	props<{ error: HttpErrorResponse }>()
// );

export const UpdateMessage = createAction(
	'[Websocket] Update Message',
	props<{ payload: any }>()
);

export const UpdateMessageAppointment = createAction(
	'[Websocket] Update Message Appointment',
	props<{ payload: any }>()
);

export const IgnoreDeviceRevoke = createAction(
	'[Websocket] Ignore Device Revoke',
	props<{ ignoreDeviceRevoke: boolean }>()
);

export const CloseAction = createAction(
	'[Websocket] Close',
	props<{ payload?: { forceClose?: boolean, url?: string } }>()
);

export const CloseComplete = createAction(
	'[Websocket] Close Complete'
);
