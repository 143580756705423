<app-kds-onboarding-tour class="position bottom-left"
                         *ngIf="showProviderTour"
                         [@AnimationFade]
                         [step]="stepProviderTour"
                         [stepArray]="AppProviderTour"
                         [drop]="AppProviderTourDropping"
                         (changes)="onboardingProvider($event)">
</app-kds-onboarding-tour>

<router-outlet></router-outlet>
