import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ONBOARDING_FEATURE_KEY, State, OnboardingPartialState } from './onboarding.reducer';

// Lookup the 'Onboarding' feature state managed by NgRx
export const getOnboardingState = createFeatureSelector<OnboardingPartialState, State>(ONBOARDING_FEATURE_KEY);

export const getLoading = createSelector(
	getOnboardingState,
	(state: State) => state.loading
);

export const getStep = createSelector(
	getOnboardingState,
	(state: State) => state.step
);

export const getError = createSelector(
	getOnboardingState,
	(state: State) => state.error
);
