import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { filter, tap } from 'rxjs/operators';
import { AbstractComponent } from '@gp-angular/shared/abstract';
import { PageEventPagination } from '@gp-angular/shared/schema';
import { AdminService } from '@gp-angular/service/admin';
import { ProviderService } from '@gp-angular/service/provider';
import { HistoryTypeEnumDTO, PageablePersonHistoryResponseDTO, PersonHistoryDTO } from '@noventi/gp-platform/care-providers';
import { PageableRequestDTO } from '@noventi/gp-platform/users';

@Component({
	selector: 'gp-provider-history',
	templateUrl: './provider-history.component.html',
	styleUrls: ['./provider-history.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminProviderHistoryComponent extends AbstractComponent implements OnInit {

	private _providerId: number;

	public loading = true;

	public pageablePersonHistory: PageablePersonHistoryResponseDTO;

	public personHistoryList: Array<PersonHistoryDTO>;

	public request: PageableRequestDTO;

	public pageEvent: PageEvent = {...PageEventPagination, pageSize: 25};

	public pageIndex = 0;

	public HistoryTypeEnumDTO = HistoryTypeEnumDTO;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private adminService: AdminService,
		private changeDetectorRef: ChangeDetectorRef,
		private providerService: ProviderService,
	) {
		super();
	}

	public ngOnInit(): void {
		super.addSubscription(this.providerService.readProvider$()
			.pipe(
				filter(next => !!next),
				tap((next) => this._providerId = next.id)
			)
			.subscribe(() => this.loadProviderHistory()));


		super.addSubscription(this.adminService.getProviderHistoryList$()
			.pipe(
				filter(next => !!next)
			).subscribe(
				(next) => {
					this.pageablePersonHistory = next;
					this.personHistoryList = this.pageablePersonHistory.data;
					this._loading();
				},
				() => this._loading())
		);
	}


	public loadProviderHistory() {
		this.adminService.loadProviderHistoryList(this._providerId, this.data.historyType, this.pageEvent);
	}

	public getTableColumns() {
		switch (this.data.historyType) {
			case HistoryTypeEnumDTO.PERSON:
				return ['RevisionDate', 'ModifiedBy', 'RevisionType', 'Hidden', 'Salutation', 'Title',
					'Firstname', 'Lastname', 'Email', 'Telefon', 'Fax', 'Street', 'HouseNumber', 'PostalCode', 'City',
					'Homepage', 'AccountStatus', 'PracticeName', 'NoventiCustomerNumber', 'PublicBillingType', 'PrivateBillingType'];
			case HistoryTypeEnumDTO.RESOURCES:
				return ['RevisionDate', 'ModifiedBy', 'RevisionType', 'LastName', 'Type', 'Priority', 'Color', 'DefaultResource'];
			case HistoryTypeEnumDTO.OPENHOURS:
				return ['RevisionDate', 'ModifiedBy', 'RevisionType', 'Day', 'Type', 'From', 'To'];
			case HistoryTypeEnumDTO.MESSAGETEMPLATE:
				return ['RevisionDate', 'ModifiedBy', 'RevisionType', 'Category', 'Type', 'Content', 'Active', 'SystemTemplate',
					'Attachments'];
			default:
				return ['RevisionDate', 'ModifiedBy', 'RevisionType', 'Name'];
		}

	}

	/**
	 * Revision Type (create = 0, edited = 1, deleted = 2)
	 */
	public getRevisionType(revisionType: number) {
		switch (revisionType) {
			case 0:
				return 'Pages.Provider.History.Created';
			case 1:
				return 'Pages.Provider.History.Modified';
			case 2:
				return 'Pages.Provider.History.Deleted';
			default:
				return '';
		}
	}

	private _loading(value: boolean = false): void {
		this.loading = value;
		this.changeDetectorRef.markForCheck();
	}
}
