import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { PAGE } from '@gp-angular/shared/schema';
import { UserService } from '@gp-angular/service/user';

@Component({
	selector: 'gp-provider-menu-aside',
	templateUrl: './menu-aside.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProviderMenuAsideComponent {

	public readonly PAGE = PAGE;

	public permission$ = this.userService.getPermission$();

	constructor(
		private userService: UserService
	) {
	}
}
