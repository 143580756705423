import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromOnboarding from './+state/onboarding.reducer';
import { OnboardingEffects } from './+state/onboarding.effects';
import { OnboardingFacade } from './+state/onboarding.facade';
import { ApiKdsOnboardingService } from './api-kds-obboarding.service';
import { OnboardingTourComponent } from './onboarding-tour/onboarding-tour.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	declarations: [
		OnboardingTourComponent
	],
	imports: [
		CommonModule,
		StoreModule.forFeature(fromOnboarding.ONBOARDING_FEATURE_KEY, fromOnboarding.reducer),
		EffectsModule.forFeature([OnboardingEffects]),

		MatButtonModule,
		MatIconModule,
	],
	exports: [
		OnboardingTourComponent
	],
	providers: [
		OnboardingFacade,
		ApiKdsOnboardingService
	]
})
export class ApiKdsOnboardingModule {
	static forRoot(): ModuleWithProviders<ApiKdsOnboardingModule> {
		return {
			ngModule: ApiKdsOnboardingModule
		};
	}
}
