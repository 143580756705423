import { Injectable } from '@angular/core';
//import { createEffect, Actions, ofType } from '@ngrx/effects';

//import * as OnboardingFeature from './onboarding.reducer';
//import * as OnboardingActions from './onboarding.actions';

@Injectable()
export class OnboardingEffects {
	//constructor(private actions$: Actions) {
	//}
}
