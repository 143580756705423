import { Inject, Injectable } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as WebsocketActions from './+state/websocket.actions';
import { WebsocketFacade } from './+state/websocket.facade';

@Injectable({
	providedIn: 'root'
})
export class WebsocketService {

	constructor(
		@Inject('stompConfig') private socket,
		private actionsSubject$: ActionsSubject,
		private websocketFacade: WebsocketFacade
	) {
	}

	public subscribeNotificationChannel$(channel?: string): Observable<any> {
		const CHANNEL = !!channel ? channel : this.socket.channelMap.provider.callbacks;
		this.websocketFacade.dispatch(WebsocketActions.SubscribeChannel({
			payload: {socketUrl: this.socket.brokerURL, channelUrl: CHANNEL}
		}));

		return this.actionsSubject$.pipe(
			ofType(WebsocketActions.UpdateMessage),
			switchMap((action) => of(action.payload))
		);
	}

	// TODO: Div review
	public subscribeNotificationAppointment$(channel: string): Observable<any> {
		this.websocketFacade.dispatch(WebsocketActions.SubscribeChannelAppointment({
			payload: {socketUrl: this.socket.brokerURL, channelUrl: channel}
		}));

		return this.listenNotificationAppointment$();
	}

	public listenNotificationAppointment$(): Observable<any> {
		return this.actionsSubject$.pipe(
			ofType(WebsocketActions.UpdateMessageAppointment),
			switchMap((action) => of(action.payload))
		);
	}

	public ignoreDeviceRevoke(value: boolean = false): Observable<boolean> {
		this.websocketFacade.dispatch(WebsocketActions.IgnoreDeviceRevoke({ignoreDeviceRevoke: value}));

		return this.actionsSubject$.pipe(
			ofType(WebsocketActions.IgnoreDeviceRevoke),
			switchMap((action) => of(true))
		);
	}

	public close$(): Observable<boolean> {
		this.websocketFacade.dispatch(WebsocketActions.CloseAction({payload: {forceClose: false}}));

		return this.actionsSubject$.pipe(
			ofType(WebsocketActions.CloseComplete),
			switchMap((action) => of(true))
		);

	}
}
