import { trigger, animate, transition, style, state } from '@angular/animations';

export const AnimationFade = trigger('AnimationFade', [
	// the "in" style determines the "resting" state of the element when it is visible.
	state('in', style({opacity: 1})),

	// fade in when created. this could also be written as transition('void => *')
	transition(':enter', [
		style({opacity: 0}),
		animate(800)
	]),

	// fade out when destroyed. this could also be written as transition('void => *')
	transition(':leave',
		animate(800, style({opacity: 0})))
]);

export const AnimationStepChange = trigger('AnimationStepChange', [
	state('in', style({opacity: 1, transform: 'scale(1)'})),
	transition(':enter', [
		style({opacity: 0, transform: 'scale(0)', height: '0'}),
		animate(400)
	]),
	transition(':leave',
		animate(200, style({opacity: 0, transform: 'scale(0)', height: '0'}))
	)
]);

export const AnimationSizeChange = trigger('AnimationSizeChange', [
	state('in', style({opacity: 1, transform: 'scale(1)'})),
	transition(':enter', [
		style({opacity: 0, transform: 'scale(0)', height: '0'}),
		animate(200)
	]),
	transition(':leave',
		animate(200, style({opacity: 0, transform: 'scale(0)', height: '0', width: '0'}))
	)
]);
