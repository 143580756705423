import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { PAGE } from '@gp-angular/shared/schema';

@Component({
	selector: 'gp-provider-legal',
	templateUrl: './legal.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProviderLegalComponent {

	public readonly PAGE = PAGE;

	constructor() {
	}
}
