import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PAGE } from '@gp-angular/shared/schema';

@Injectable()
export class RedirectProviderSyncGoogleGuard implements CanActivate {

	constructor(
		@Inject('ENVIRONMENT') private _environment: any,
		private router: Router
	) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const CODE = route.queryParams && route.queryParams.code ? route.queryParams.code : undefined;
		if (!!CODE) {
			this.router.navigate([PAGE.PROVIDER_SETTINGS_SYNC.fullpath], {queryParams: {gc: CODE}}).then();
		}
		return !!CODE;
	}
}
