import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StompConfig } from './stomp/stomp.config';
import { StompService } from './stomp.service';
import { WebsocketService } from './websocket.service';
import * as fromWebsocket from './+state/websocket.reducer';
import { WebsocketEffects } from './+state/websocket.effects';
import { WebsocketFacade } from './+state/websocket.facade';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(fromWebsocket.WEBSOCKET_FEATURE_KEY, fromWebsocket.reducer),
		EffectsModule.forFeature([WebsocketEffects]),
	],
	providers: [WebsocketFacade, StompConfig, StompService]
})
export class ServiceWebsocketModule {
	static forRoot(): ModuleWithProviders<ServiceWebsocketModule> {
		return {
			ngModule: ServiceWebsocketModule,
			providers: [WebsocketService, StompConfig, StompService]
		};
	}
}
