import { createReducer, on, Action } from '@ngrx/store';

import * as OnboardingActions from './onboarding.actions';

export const ONBOARDING_FEATURE_KEY = 'onboarding';

export interface State {
	step?: number;
	loading: boolean;
	error: string | null;
}

export interface OnboardingPartialState {
	readonly [ONBOARDING_FEATURE_KEY]: State;
}

export const initialState: State = {
	step: undefined,
	loading: false,
	error: undefined
};

const OnboardingReducer = createReducer(
	initialState,
	on(OnboardingActions.Init, (state) => ({...state, loading: false, error: null})),
	on(OnboardingActions.SetStep, (state, {step}) => ({...state, step})),
	on(OnboardingActions.ClearState, () => ({...initialState})),
);

export function reducer(state: State | undefined, action: Action) {
	return OnboardingReducer(state, action);
}
