import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as OnboardingSelectors from './onboarding.selectors';

@Injectable()
export class OnboardingFacade {
	step$ = this.store.pipe(select(OnboardingSelectors.getStep));
	loading$ = this.store.pipe(select(OnboardingSelectors.getLoading));
	error$ = this.store.pipe(select(OnboardingSelectors.getError));

	constructor(private store: Store) {
	}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}
}
