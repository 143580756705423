<div class="institution-select-wrapper flex flex-column" [ngClass]="{'open': opened, 'loading': loading}">
    <button mat-button class="" [matMenuTriggerFor]="institutionMenu" [disableRipple]="true"
            (menuOpened)="opened = true;" (menuClosed)="opened = false;" [disabled]="loading">
        <mat-icon class="padding-vertical hide-on-open hide-on-small-and-down">location_on</mat-icon>
        <div class="flex-center flex-space-between padding-vertical-S hide-on-close" *ngIf="(currentPid$ | async) as currentPid">
            <div class="flex flex-column">
                <span>{{ 'App.General.Location' | translate }}</span>
                <span class="name" *ngIf="(list$ | async) as list; else empty">{{ getCurrentPid(currentPid, list).practiceName }}</span>
            </div>
            <mat-icon *ngIf="!loading">expand_more</mat-icon>
            <mat-progress-spinner *ngIf="loading" mode="indeterminate" [diameter]="24" class="margin-right-S"></mat-progress-spinner>
        </div>
    </button>
    <mat-menu #institutionMenu="matMenu" backdropClass="institution-menu" xPosition="after" yPosition="below">
        <mat-divider></mat-divider>
        <ng-container *ngFor="let item of (list$ | async)">
            <button mat-menu-item (click)="setCurrentPid(item.id)">
                <div class="flex-center flex-space-between institution-name">
                    <span class="text-nowrap text-trim">{{item.practiceName}}</span>
                    <mat-icon *ngIf="(currentPid$ | async) === item.id">check_circle_outline</mat-icon>
                </div>
                <div *ngIf="(defaultPid$ | async) === item.id" class="default padding-horizontal">{{'App.General.Default' | translate }}</div>
            </button>
            <mat-divider></mat-divider>
        </ng-container>

        <button mat-flat-button color="primary" class="margin" [routerLink]="PAGE.PROVIDER_INSTITUTION.fullpath">
            {{ 'App.General.Location_Management' | translate }}
        </button>
    </mat-menu>
</div>

<ng-template #empty><br></ng-template>
