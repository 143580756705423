<mat-nav-list class="submenu no-padding" *ngIf="(permission$ | async) as permission">
    <div class="mat-list-item header-group">
        <div class="mat-list-item-content">{{'App.Menu.Header.My_Appointment_Booking' | translate | uppercase}}</div>
    </div>

    <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('PROFILE_DATA_VIEW')"
       [routerLink]="PAGE.PROVIDER_INFORMATION.fullpath">
        {{'Api.ProviderProfile.Page_Name.Information' | translate}}
    </a>


    <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('TREATMENTS_VIEW')"
       [routerLink]="PAGE.PROVIDER_TREATMENT.fullpath">
        {{'Api.ProviderTreatment.Title' | translate}}
    </a>

    <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('RESOURCE_OVERVIEW_VIEW')"
       [routerLink]="PAGE.PROVIDER_RESOURCE.fullpath">
        {{'Api.ProviderResource.Title' | translate}}
    </a>

    <ng-container *ngIf="permission.includes('OFFICE_HOURS_VIEW' || 'SERVICES_VIEW' || 'EXPERTISE_AREA_VIEW' || 'PROFILE_ADDITIONAL_DATA_VIEW' || 'TEXT_TEMPLATES_VIEW' || 'INSTITUTION_SETTINGS_VIEW')">
        <mat-divider class="relative margin-horizontal"></mat-divider>

        <div class="mat-list-item header-group">
            <div class="mat-list-item-content">{{'App.Menu.Header.My_Practice_Data' | translate | uppercase}}</div>
        </div>

        <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('OFFICE_HOURS_VIEW')"
           [routerLink]="PAGE.PROVIDER_SCHEDULE.fullpath">
            {{'Pages.Provider.Schedule.Label' | translate}}
        </a>
        <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('SERVICES_VIEW')"
           [routerLink]="PAGE.PROVIDER_SERVICE.fullpath">
            {{'Pages.Provider.Service.Label' | translate}}
        </a>
        <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('EXPERTISE_AREA_VIEW')"
           [routerLink]="PAGE.PROVIDER_EXPERTISE.fullpath">
            {{'Pages.Provider.Expertise.Label' | translate}}
        </a>
        <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('PROFILE_ADDITIONAL_DATA_VIEW')"
           [routerLink]="PAGE.PROVIDER_EXTRA_INFO.fullpath">
            {{'Pages.Provider.Extra_Info.Label' | translate}}
        </a>

        <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('INSTITUTION_SETTINGS_VIEW')"
           [routerLink]="PAGE.PROVIDER_INSTITUTION_SETTINGS.fullpath">
            {{'Api.ProviderProfile.Institution_Settings.Title' | translate}}
        </a>
    </ng-container>
</mat-nav-list>
