import { Injectable } from '@angular/core';
import { asyncScheduler, Observable, scheduled } from 'rxjs';
import { ActionsSubject } from '@ngrx/store';

import * as OnboardingActions from './+state/onboarding.actions';
import { OnboardingFacade } from './+state/onboarding.facade';

@Injectable()
export class ApiKdsOnboardingService {

	constructor(
		private actionsSubject$: ActionsSubject,
		private onboardingFacade: OnboardingFacade
	) {
	}

	public init() {
		this.onboardingFacade.dispatch(OnboardingActions.Init());
	}

	public setStep(step: number) {
		this.onboardingFacade.dispatch(OnboardingActions.SetStep({step: step}));
	}

	public step$(): Observable<number> {
		return this.onboardingFacade.step$;
	}

	public error$(): Observable<any> {
		return this.onboardingFacade.error$;
	}

	public clearState$(): Observable<boolean> {
		this.onboardingFacade.dispatch(OnboardingActions.ClearState());
		return scheduled([true], asyncScheduler);
	}
}
