import { createAction, props } from '@ngrx/store';

export const Init = createAction('[Onboarding] Init');

export const SetStep = createAction(
	'[Onboarding] Set Step',
	props<{ step: number }>()
);

export const ClearState = createAction('[Onboarding] Clear state');
