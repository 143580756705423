import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '@gp-angular/service/user';
import { filter, take } from 'rxjs/operators';

@Injectable()
export class AppUserResolver implements Resolve<any> {
	constructor(
		private userService: UserService
	) {
	}

	resolve() {
		this.userService.loadUser$()
			.pipe(
				filter((next) => !!next),
				take(1)
			);
	}
}
