import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HashIdService } from './hash-id.service';

@NgModule({
  imports: [CommonModule],
})
export class ServiceHashIdModule {
	static forRoot(): ModuleWithProviders<ServiceHashIdModule> {
		return {
			ngModule: ServiceHashIdModule,
			providers: [HashIdService]
		};
	}
}
