export const environment = {
	production: false,
	name: 'admin-ui',
	app: 'admin',
	env: undefined,
	version: undefined,
	fcKey: undefined,
	endpoints: undefined,
	websocket: undefined,
	analytics: undefined
};
