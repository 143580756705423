<button *ngIf="!!resourceCategoryList && resourceCategoryList.length > 0" mat-icon-button [matMenuTriggerFor]="menu" class="hide-on-small-and-down">
    <mat-icon>announcement</mat-icon>
    <span class="red-dot"></span>
</button>
<mat-menu #menu="matMenu" xPosition="before" backdropClass="resource-warning-menu">
    <ng-container *ngFor="let resourceCategory of resourceCategoryList">
        <ng-container *ngIf="resourceCategory.items.length > 0">
            <div class="title padding">{{'App.Header.Resource_Warning.' + resourceCategory.type + '.Title' | translate | uppercase}}</div>
            <ng-container *ngFor="let resource of resourceCategory.items">

                <button mat-menu-item *ngIf="!resource.hasBookingHours && !resource.hasOnlineBookingHours; else onlyOneMissing" class="text-wrap"
                        [disabled]="isEmployee ? employeeResourceId !== resource.id : false"
                        [routerLink]="PAGE.PROVIDER_RESOURCE.fullpath + '/' + hashIdService.encode(resource.id) + '/' + PAGE.RESOURCE_BUSINESS_HOURS.path">
                    <mat-icon>arrow_right</mat-icon>
                    <span [innerHTML]="'App.Header.Resource_Warning.' + resource.type + '.Both' | translate: {name: resource.type === ResourceTypeEnumDTO.EMPLOYEE ?
                                ResourceUtils.displayName(resource, true) : resource.name}"></span>
                </button>
                <ng-template #onlyOneMissing>
                    <button mat-menu-item *ngIf="!resource.hasBookingHours" class="text-wrap"
                            [disabled]="isEmployee ? employeeResourceId !== resource.id : false"
                            [routerLink]="PAGE.PROVIDER_RESOURCE.fullpath + '/' + hashIdService.encode(resource.id) + '/' + PAGE.RESOURCE_BUSINESS_HOURS.path">
                        <mat-icon>arrow_right</mat-icon>
                        <span [innerHTML]="'App.Header.Resource_Warning.' + resource.type + '.BookingHours' | translate: {name: resource.type === ResourceTypeEnumDTO.EMPLOYEE ?
                                    ResourceUtils.displayName(resource, true) : resource.name}"></span>
                    </button>
                    <button mat-menu-item *ngIf="!resource.hasOnlineBookingHours" class="text-wrap"
                            [disabled]="isEmployee ? employeeResourceId !== resource.id : false"
                            [routerLink]="PAGE.PROVIDER_RESOURCE.fullpath + '/' + hashIdService.encode(resource.id) + '/' + PAGE.RESOURCE_ONLINE_APPOINTMENTS.path">
                        <mat-icon>arrow_right</mat-icon>
                        <span [innerHTML]="'App.Header.Resource_Warning.' + resource.type + '.OnlineBookingHours' | translate: {name: resource.type === ResourceTypeEnumDTO.EMPLOYEE ?
                                    ResourceUtils.displayName(resource, true) : resource.name}"></span>
                    </button>
                </ng-template>
            </ng-container>
        </ng-container>
    </ng-container>
</mat-menu>
